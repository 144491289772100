<template>
  <v-dialog
    :value="value"
    @change="handleDialog"
    persistent
    max-width="600"
    eager
  >
    <v-card>
      <v-card-title> Atribuição manual </v-card-title>
      <v-card-subtitle>
        Escolha a unidade para o serviço atual
      </v-card-subtitle>
      <v-form @submit.prevent="handleSubmitForm" :disabled="loading_submit">
        <v-card-text>
          <UnityAutocomplete
            label="Unidade"
            v-model="unity"
            outlined
            class="px-3"
            :error-messages="unityErrors"
          />
          <v-text-field
            v-model="motive"
            label="Motivo"
            placeholder="Justificativa de atribuição manual"
            class="px-3"
            counter="255"
            :error-messages="motiveErrors"
            outlined
          ></v-text-field>

          <div class="pt-5 text-right">
            <v-btn @click="resetDialog()" color="primary" text>
              Cancelar
            </v-btn>
            <v-btn type="submit" color="primary"> Confirmar </v-btn>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { indexUnitiesForLead, assign, unassign } from "@/services/lead_unities";
import UnityAutocomplete from "../unities/UnityAutocomplete.vue";

export default {
  name: "change-lead-unity-dialog",
  props: { value: Boolean },
  components: { UnityAutocomplete },
  data() {
    return {
      loading_unities: false,
      loading_submit: false,
      sugestions: [],
      unity: null,
      unityErrors: [],
      motive: "",
      motiveErrors: [],
    };
  },
  methods: {
    handleDialog($event) {
      this.$emit("input", $event);
    },
    resetDialog() {
      this.motiveErrors = [];
      this.unityErrors = [];
      this.unity = null;
      this.handleDialog(false);
    },
    async assignUnity(unity_id, motive) {
      const response = await assign(this.lead?.id, {
        unity_id,
        motive,
      });

      this.$store.commit("setLead", {
        ...this.lead,
        unity: response,
        unity_id: response.id,
      });

      return response;
    },
    async unassignUnity(motive) {
      const response = await unassign(this.lead?.id, {
        motive,
      });

      this.$store.commit("setLead", {
        ...this.lead,
        unity: response,
        unity_id: response.id,
      });

      return response;
    },
    async handleSubmitForm() {
      this.loading_submit = true;
      this.motiveErrors = [];
      this.unityErrors = [];
      try {
        await this.assignUnity(this.unity?.id, this.motive);
        this.resetDialog();
      } catch (error) {
        console.error(error);
        this.$store.commit("error", error);
        if (error.response.status === 422) {
          this.motiveErrors = error.response.data.errors.motive || [];
          this.unityErrors = error.response.data.errors.unity_id || [];
        }
      } finally {
        this.loading_submit = false;
      }
    },
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
  },
  watch: {
    value: function (value) {
      if (value) {
        this.motive = "";
      }
    },
  },
};
</script>
