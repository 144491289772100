import Vue from "vue";

export async function assign(lead_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_id}/unity/assign`,
    payload
  );

  return response.data;
}

export async function unassign(lead_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_id}/unity/unassign`,
    payload
  );

  return response.data;
}

export async function getHistory(lead_id) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_id}/unity/history`
  );

  return response.data;
}

export async function sugestAndAssign(lead_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/leads/${lead_id}/unity/sugest_and_assign`,
    payload
  );

  return response.data;
}

export async function indexUnitiesForLead(lead_identifier_code) {
  const response = await Vue.prototype.$http.get(
    `/unities/sugestion/${lead_identifier_code}`
  );

  return response.data;
}
